<template>
  <div class="content flex-col" v-loading="loading">
    <div class="body flex-col">
      <div class="box">
        <div id="write-article-left" class="left" :style="{
          marginRight: isHideTab ? 0 : '15px'
        }">
          <article-left ref="articleLeft" @save="saveDetail($event)" :article="article" />
        </div>
        <div
          class="right"
          v-if="!article.article_type"
          :style="{ paddingRight: isHideTab ? '20px' : '20px' }"
        >
          <div class="right-close" v-if="isHideTab" @click="toggleRight">
            &#171;
          </div>

          <article-right
            ref="articleRight"
            :article="article"
            :vote-options="voteObj"
            :hide="isHideTab"
            :post_check_enable="post_check_enable"
            @hide="isHideTab = $event"
            @preview="isPreview = true"
            @time-post="showSetTime = true"
            @save="saveDetail($event)"
            @refresh-vote-list="getChosenVotes(true)"
            @update-positions="selectedPositions = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleDetail } from "../api/write-article/index";
import { saveDetail } from "../api/write-article/article-right";
import { getChosenVotes } from "../api/write-article/article-right/article-vote";
import Compare from "@/base/utils/object-compare.js";

import AddSubjects from "../components/WriteArticle/AddSubjects";
import ArticleRight from "../components/WriteArticle/ArticleRight";
import ArticleLeft from "../components/WriteArticle/ArticleLeft";
import Reminder from "../../common/components/Reminder";
import { getPostConfig } from "@/modules/article-admin/api/write-article";

export default {
  name: "write-article",
  components: {
    ArticleLeft,
    ArticleRight,
    AddSubjects,
    Reminder,
  },
  data() {
    return {
      loading: false,
      // 是否开启审核机制，不开启则新增编辑都直接发布到文章列表
      post_check_enable: 1,
      article: {
        article_type: 0, // 0：社团云文章，1：公众号文章
        // 0 是新增
        id: this.$route.query.id || 0,
        title: "",
        content: "",
        summary: "",
        wx_url: "", // 公众号文章链接
        // 类型（0:纯文本，1:图文，2:大图，3:视频）默认-1全部
        type: 1,
        commentable: 1,
        // platforms: [],
        category_id: "",
        source_id: "",
        // 状态(0：草稿，1：已发布，2：待审核，3：不通过，4：待发布，5：不采用，6：定时发布，7：已撤回)
        status: 0,
        delete_time: 0,
        post_time: 0,
        // 置顶
        top_position: 0,
        top_start_time: 0,
        top_end_time: 0,
        tags: [],
        columns: [],
        // 保存时为 json 字符串
        images: [],
        video: {},
        // 分享图
        share_image: "",
        subjects: [{ column_id: "", subject_id: "" }],
        view_positions: [],
        member_limit: 1,
        non_member_limit: 1,
        files: [],
        // 信息收集
        opinion_status:0 
      },
      elderData: {}, // 刚进页面时的文章数据
      // 跳转来源
      comeFrom: this.$route.query.come_from || "",
      selectedPositions: [],
      isHideTab: false,
      voteObj: {
        choose: [],
        is_vote: 0,
        vote_all: [],
      }
    };
  },
  created() {
    if (this.article.id) {
      this.getDetail();
    } else {
      this.loading = true;
      this.getChosenVotes();
    }
    this.getPostConfig();
  },
  mounted() {
    let that = this;
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    window.onbeforeunload = function (e) {
      console.log("on before unload");
      e = e || window.event; // 兼容IE8和Firefox 4之前的版本
      const article = that.getNowArticleData();
      if (Compare(that.elderData, article)) {
        console.log("no change");
      } else {
        console.log("modified");
        if (e) {
          e.returnValue = "当前页面有修改，是否放弃更改？";
        } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "当前页面有修改，是否放弃更改？";
      }
      console.log(article);
    };
  },
  methods: {
    /**
     * 获取设置的发布配置
     */
    getPostConfig() {
      getPostConfig()
        .then((res) => {
          if (res && res.data) {
            this.post_check_enable = res.data.post_check_enable;
          }
        })
        .catch(() => {});
    },
    // 展开 | 收起
    toggleRight() {
      this.isHideTab = !this.isHideTab;
    },
    getDetail() {
      this.loading = true;
      getArticleDetail({
        id: this.article.id,
        hashid: [1, '1'].includes(this.$route.query.hash) ? this.article.id : ''
      })
        .then((res) => {
          res.data.video = res.data.video || {};
          res.data.images = res.data.images || [];
          /* el-upload 组件的文件列表属性（file-list）识别name为文件名，url为文件链接，可携带其他参数 */
          res.data.files =
            res.data.files && res.data.files.length
              ? res.data.files.map((el) => {
                  return { ...el, name: el.title };
                })
              : [];
          this.article = res.data;
          this.getChosenVotes();
          if (res.data.content) {
            setTimeout(() => {
              /* 调用子组件内的方法，字数统计 */
              this.$refs["articleRight"]?.getArticleContent(res.data.content);
            }, 500);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doSave(data, callback) {
        this.loading = true;
        saveDetail({
          ...data,
        })
          .then((res) => {
            callback(res);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    saveDetail(type) {
      this.$refs.articleLeft?.$refs.leftForm.validate(valid => {
        if (valid) {
          this.doSave(this.getNowArticleData(type), (res) => {
            /* 保存后更新status，否则对检测修改有影响 */
            this.article.status = res.data.status;
            /* 更新数据备份 */
            this.elderData = JSON.parse(JSON.stringify(this.getNowArticleData()));
            this.$message.success(res.msg);
            if (this.article.id == 0) {
              this.$router.push({ name: "MyArticleList" });
            } else if (this.comeFrom) {
              // 跳转到文章列表
              this.$router.push({ name: this.comeFrom });
            } else {
              this.$router.push({ name: "ArticleList" });
            }
          });
        } else {
          // console.log(document.getElementsByClassName('el-form-item is-error'))
          this.$nextTick(() => {
            document.getElementsByClassName('el-form-item is-error')[0]?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          })
        }
      })
    },
    // 判断离开路由的存为草稿
    saveDetailRouteLeave(type, callback) {
      this.doSave(this.getNowArticleData(type), (res) => {
        /* 保存后更新status，否则对检测修改有影响 */
        this.article.status = res.data.status;
        /* 更新数据备份 */
        this.elderData = JSON.parse(JSON.stringify(this.getNowArticleData()));
        this.$message.success(res.msg);
        callback();
      });
    },
    /**
     * 文章详情获取成功后调用
     */
    getChosenVotes(bol) {
      getChosenVotes(this.article.id)
        .then((res) => {
          if (bol) {
            // 只更新列表
            this.voteObj.vote_all = res.data.vote_all;
          } else {
            this.voteObj = res.data;
            /* 备份初始数据 */
            this.elderData = JSON.parse(
              JSON.stringify(this.getNowArticleData())
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     *  构造提交保存接口的数据
     *  用于初始备份、改动检测
     * */
    getNowArticleData(type) {
      let data = JSON.parse(JSON.stringify(this.article));
      /* 标签格式为 id 数组 */
      let tagList = [];
      if (data.tags.length > 5) {
        // this.$message.error('标签不能超过五个')
      } else if (data.tags.length > 0) {
        tagList = data.tags.map((item) => item.id);
      }
      let subjectList = [];
      /* 如果需要保存对象数组 */
      // let temp = {};
      // 过滤空字段
      // data.subjects.filter(item => item.column_id !== "").forEach(it => {
      //   if (!temp[it.column_id]) {
      //     subjectList.push(it);
      //     // 过滤column_id字段，后面重复的将被忽略
      //     temp[it.column_id] = true;
      //   }
      // })
      /* 只需要column_id数组 */
      subjectList = data.subjects
        .filter((item) => item.column_id !== "")
        .map((item) => item.column_id);
      subjectList = Array.from(new Set(subjectList));
      return {
        ...this.article,
        status: type || data.status,
        images:
          data.images && data.images.length
            ? JSON.stringify(data.images)
            : "[]",
        video: JSON.stringify(data.video),
        /* 投票 */
        is_vote: this.voteObj.is_vote,
        vote_id: this.voteObj.choose.map((it) => it.id),
        /* 置顶 */
        /* 置顶位置已在`this.article`里 */
        top_start_time:
          this.article.top_start_time == -1 ? 0 : this.article.top_start_time,
        top_end_time:
          this.article.top_end_time == -1 ? 0 : this.article.top_end_time,
        /* 标签 */
        tags: tagList,
        /* 专题 */
        columns: subjectList,
        files: this.article.files.map((el) => el.id),
        // view_positions: JSON.stringify(this.article.view_positions)
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    const article = this.getNowArticleData();
    if (Compare(this.elderData, article)) {
      next();
    } else {
      this.$msgbox
        .confirm("当前页面有修改，是否放弃更改？", "提示", {
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: this.article.id ? "取消" : "存为草稿",
        })
        .then((res) => {
          next();
        })
        .catch(async (action) => {
          if (action === "cancel") {
            if (this.article.id) {
              // 编辑文章不显示存为草稿
              next(false);
            } else {
              // 存为草稿
              await this.saveDetailRouteLeave(0, () => {
                next();
              });
            }
          } else {
            // 关闭
            next(false);
          }
        });
    }
  },
  beforeDestroy() {
    // 离开当前路由时注销浏览器关闭前弹窗事件
    window.onbeforeunload = null;
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-left: -$space;
  margin-right: calc(-#{$space} * 2);
  margin-top: -28px;
  margin-bottom: -$space;
  background-color: #f5f6f7;
  flex: 1;

  .body {
    flex: 1;
  }

  .right-close {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 40vh;
    /*top: 50%;*/
    /*transform: translateX(-50%);*/
    height: 60px;
    line-height: 60px;
    width: 16px;
    background-color: #e1e1e1;
    color: white;
    padding-left: 2px;
    border-radius: 8px 0 0 8px;
    /*box-shadow: 0 0 2px 2px #f1f1f1;*/

    &:hover {
      opacity: 0.7;
    }
  }
}

.box {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1;

  .left {
    @include card();
    padding-right: 20px;
    /*min-width: 628px;*/
    min-width: 0;
    width: 100%;
  }

  .right {
    margin-left: 10px;
    position: relative;
  }
}

.article-import {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-form-item.is-error {
  .tox-tinymce {
    border-color: #FF4949 !important;
  }
}
</style>
