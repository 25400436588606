import api from "@/base/utils/request";

/**
 * 文章详情
 * @param data
 */
export const getArticleDetail = data => {
  return api({
    url: '/admin/portal/article/detail',
    method: 'post',
    data
  })
}

/**
 * 获取发布配置
 */
export const getPostConfig = () => {
  return api({
    url: "/admin/portal/article/getPostConfig",
    method: "post"
  });
};
