import api from "@/base/utils/request";

/**
 * 获取当前文章开启的投票列表
 * @param id  文章id
 */
export const getChosenVotes = (id) => {
  return api({
    url: '/admin/portal/article/writeArticleVotes',
    method: 'post',
    data: {id}
  })
}

/**
 * 写文章 - 投票 - 搜索
 * @param keyword   关键词
 */
export const searchVotes = (keyword) => {
  return api({
    url: '/admin/vote/vote/keywordData',
    method: 'post',
    data: {keyword}
  })
}

// 投票-新增&编辑
export const voteSave = data => {
  return api({
    url: '/admin/vote/vote/save',
    method: 'post',
    data
  });
};
