<template>
  <div class="article-left">
    <reminder v-if="articleType" class="reminder">
      <el-row>
        <el-col :span="23">
          <div>
            使用公众号文章的功能前，需要在公众号后台关联小程序
            <a
              class="reminder-link"
              href="https://www.yuque.com/ax101w/yz6xgk/ftxeo0"
              target="_blank"
              >查看关联指引</a
            >
          </div>
        </el-col>
      </el-row>
    </reminder>
    <el-form
      ref="leftForm"
      class="medium-form"
      size="medium"
      :model="article"
      :rules="rules"
      label-width="80px"
    >
      <div class="modular">
        <p class="modular-title">文章信息</p>
        <div class="modular-content">
          <el-form-item label="浏览类型：">
            <el-radio-group v-model="article.article_type">
              <el-radio :label="0">在当前客户端浏览</el-radio>
              <el-radio :label="1">跳转至公众号文章内浏览</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="articleType" label="文章链接：" prop="wx_url">
            <el-input
              autofocus
              type="text"
              v-model="article.wx_url"
              placeholder="请输入"
            ></el-input>
            <el-button
              v-if="articleType"
              style="margin-left: 20px"
              type="text"
              @click="handleTitleAndCover()"
            >
              <i v-if="fetchLoading" class="el-icon-loading"></i>
              获取文章标题和封面
            </el-button>
          </el-form-item>
          <el-form-item label="文章标题：" prop="title">
            <div class="flex" style="width: 100%">
              <el-input
                type="text"
                v-model="article.title"
                placeholder="请输入"
              ></el-input>
              <el-button
                  v-if="!articleType"
                  @click="handleToolBarClick({type:'import'})"
                  type="success"
                  :style="{
                  marginLeft: '12px',
                }"
              >
                导入文章
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="选择分类：" prop="category_id">
            <div class="flex">
              <el-cascader
                v-model="article.category_id"
                :options="categories"
                :props="casProps"
                ref="levels"
                :show-all-levels="false"
                placeholder="请选择"
                filterable
                clearable
                @change="handleLevelChange"
              ></el-cascader>
              <!-- 通用添加分类组件 -->
              <AddCategoryButton
                :style="{
                  marginLeft: '12px',
                }"
                :updateList="getAllCategories"
                :getApi="getApi"
                :form="{
                  name: '',
                  sort: '',
                  pid: '',
                }"
              >
              </AddCategoryButton>
            </div>
          </el-form-item>
          <el-form-item label="文章类型：" prop="type">
            <el-select v-model="article.type" placeholder="请选择">
              <el-option
                v-for="(type, tIndex) in types"
                :key="type.id"
                :value="type.id"
                :label="type.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择来源：" prop="source_id">
            <el-select
              v-model="article.source_id"
              :disabled="portal_user_id > 0"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="(source, sIndex) in sources"
                :key="source.id"
                :value="source.id"
                :label="source.name"
              ></el-option>
            </el-select>
            <el-button @click="showAddSource=true" style="margin-left: 12px;" type="primary">添加来源</el-button>
          </el-form-item>
          <el-form-item label="所属地区：" prop="design">
            <el-cascader
              :value="article.region?article.region.split(','):''"
              :options="areaData"
              :props="{
                expandTrigger: 'hover',
                value: 'label',
              }"
              clearable
              filterable
              placeholder="请选择，可搜索"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <!-- 视频类型专属 - 选择视频 -->
          <!-- 文章详情-视频 -->
          <el-form-item v-if="article.type === 3" label="选择视频：">
            <div class="media-preview-list">
              <single-media-wall
                v-model="article.video"
                :width="194"
                :height="135"
                :borderRadius="4"
                addText="上传视频"
                UploadType="video"
              />
            </div>
          </el-form-item>
          <el-form-item
            v-if="
              article.type === 3 &&
              ((article.video && article.video.thumbnail) || article.video.url)
            "
            prop="video"
            label="上传封面："
          >
            <!-- 文章列表-视频封面图 -->
            <div class="media-preview-list">
              <div class="images-box">
                <!-- 方式一：:show-mask="false" -->
                <!--            <SingleMediaWall addText="上传图片" v-model="article.video.thumbnail" :width="194" :height="135"-->
                <!--                             :borderRadius="4" :show-mask="false">-->
                <!--              <p slot="info" class="img-tips" style="width: 194px;">-->
                <!--                建议上传尺寸比例为750*524-->
                <!--              </p>-->
                <!--            </SingleMediaWall>-->
                <!-- 方式二：多添加一个"编辑"按钮选项，directly -->
                <SingleMediaWall
                  addText="上传图片"
                  v-model="article.video.thumbnail"
                  :width="194"
                  :height="135"
                  ratio="750:524"
                  :borderRadius="4"
                  :show-cut="true"
                  directly
                  :show-delete="false"
                >
                  <p slot="info" class="img-tips" style="width: 194px">
                    建议上传尺寸比例为750*524
                  </p>
                </SingleMediaWall>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-if="!articleType" label="文章正文：" prop="content">
            <tinymce
              id="tinymce-editor"
              ref="editor"
              @choose="handleToolBarClick"
              v-model="article.content"
              show-article-import
              style="max-width: 540px"
            />
          </el-form-item>
          <!-- 类型（0:纯文本，1:图文，2:大图，3:视频）默认-1全部 -->
          <el-form-item
            label="封面图："
            prop="images"
            v-if="article.type === 1 || article.type === 2 || articleType"
          >
            <!-- 文章列表-封面图 -->
            <div class="media-preview-list">
              <div class="images-box">
                <!--
                :ratio="[article.type === 1 ? '750:524' : '1035:435']"
                -->
                <media-wall
                  addText="上传照片"
                  v-model="article.images"
                  :width="194"
                  :height="135"
                  :borderRadius="4"
                  :upload-type="['image']"
                  :limit="(article.type === 1 ? 3 : 1) - article.images.length"
                  :show-cut="true"
                  draggable
                  @change="reValidate('images')"
                  @handleMediaSrc="reValidate('images')"
                  :ratio="ratioList"
                >
                  <!--                  <p slot="info" class="img-tips" style="width: 194px">-->
                  <!--                    建议上传尺寸比例为{{-->
                  <!--                      article.type === 1 ? '750*524' : '1035*435'-->
                  <!--                    }}-->
                  <!--                    模板二-->
                  <!--                  </p>-->
                  <div
                    slot="info"
                    class="form-tip img-tips"
                    style="text-align: left"
                  >
                    <div>
                      <!--                      <span-->
                      <!--                        class="el-icon-info"-->
                      <!--                        style="font-size: 15px; color: #c0c4cc"-->
                      <!--                      ></span>-->
                      <span> 推荐尺寸： </span>
                    </div>
<!--                    <div>模板1 为 285px*213px</div>-->
<!--                    <div>模板2 为 435px*246px</div>-->
<!--                    <div>大图类型 为 957px*538px</div>-->
                    <div :key="r.ratio" v-for="r in ratioList">{{r.desc}}</div>
                  </div>
                </media-wall>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="!articleType" label="分享图：">
            <div class="media-preview-list">
              <div class="media-input-item media-preview-item">
                <SingleMediaWall
                  addText="上传照片"
                  v-model="article.share_image"
                  :width="194"
                  :height="135"
                  ratio="5:4"
                  :borderRadius="4"
                  :show-cut="true"
                >
                  <p slot="info" class="img-tips" style="width: 194px">
                    建议上传尺寸比例为1500*1200
                  </p>
                </SingleMediaWall>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="!articleType"
            label="文件上传："
            style="max-width: 440px"
          >
            <el-upload
              action="#"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :http-request="handleUpload"
              :before-upload="beforeUpload"
              multiple
              :file-list="article.files"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传word、excel、pdf、jpg、png文件，且不超过150M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="!articleType" label="文章摘要：">
            <el-input
              :rows="5"
              type="textarea"
              placeholder="请输入摘要"
              v-model="article.summary"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div v-if="articleType" class="modular">
        <p class="modular-title">添加专题</p>
        <div class="modular-content">
          <el-form-item label="专题信息：">
            <!-- 公众号文章添加专题 -->
            <add-subjects
              style="margin-bottom: 20px"
              v-model="article.subjects"
              :article-id="article.id"
            ></add-subjects>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <!-- 公众号文章提交按钮 -->
    <fixed-action-bar v-if="articleType">
      <el-button
        v-if="$route.name === 'CreateArticle'"
        @click="$emit('save', 0)"
        >存为草稿</el-button
      >
      <el-button
        v-if="!article.id || article.status === 0"
        type="primary"
        @click="$emit('save', post_check_enable ? 2 : 1)"
        >提交
      </el-button>
      <el-button
        v-if="$route.name !== 'CreateArticle'"
        type="primary"
        @click="$emit('save', article.status)"
      >
        保存
      </el-button>
      <el-button v-if="$route.name !== 'CreateArticle'" @click="$router.go(-1)"
        >返回</el-button
      >
    </fixed-action-bar>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />

    <AddSource
        :showAddSource="showAddSource"
        :editId="0"
        @closeAddSource="closeAddSource"
        @updateSourceList="updateSourceList"
    ></AddSource>
  </div>
</template>

<script>
import {
  getAllCategories,
  getAllSources,
  // getWxArticleTitle,
} from '../../api/write-article/article-left'
import { addClassify } from '@/modules/article-admin/api/classify-list.js'
import { getWxPublicArticle } from '@/base/api/common-api'
import SingleMediaWall from '../../../common/components/SingleMediaWall'
import MediaWall from '../../../common/components/MediaWall'
import Tinymce from '@/base/components/Editor/Tinymce'
import MediaSelector from '../../../common/components/MediaSelector'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
import AddSubjects from './AddSubjects'
import filesUpload from '@/base/utils/upload4'
import FixedActionBar from '../../../../base/layout/FixedActionBar'
import { getFileType } from '@/base/utils/tool'
import Reminder from '@/modules/common/components/Reminder'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
import { getCityData } from '@/modules/organization-admin/api/org-frame/custom-form'
import AddSource from '../SourceManage/AddSource'
export default {
  name: 'ArticleLeft',
  components: {
    AddSource,
    Reminder,
    ImportHtmlCode,
    ImportArticle,
    MediaSelector,
    MediaWall,
    SingleMediaWall,
    Tinymce,
    AddSubjects,
    FixedActionBar,
    AddCategoryButton,
  },
  props: {
    article: Object,
    // 是否开启审核机制，不开启则新增编辑都直接发布到文章列表
    post_check_enable: Number,
  },
  data() {
    return {
      areaData:null,
      view_type: 'native',
      portal_user_id: this.$route.query.portal_user_id || 0,
      rules: {
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章正文', trigger: 'blur' },
        ],
        wx_url: [
          { required: true, message: '请输入文章链接', trigger: 'blur' },
        ],
        category_id: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        source_id: [
          { required: true, message: '请选择来源', trigger: 'change' },
        ],
        images: [
          { required: true, message: '请选择封面图', trigger: 'change' },
          {
            validator: this.ImgsPass,
            message: '请选择封面图',
            trigger: 'change',
          },
        ],
        video: [
          { required: true, message: '请选择封面图', trigger: 'change' },
          {
            validator: this.ImgsPass,
            message: '请选择封面图',
            trigger: 'change',
          },
        ],
      },
      // 级联属性
      casProps: {
        children: '_children',
        label: 'name',
        value: 'id',
        expandTrigger: 'hover',
        emitPath: false,
        checkStrictly: true,
      },
      // 级联数据
      categories: [],
      types: [
        { id: 0, name: '纯文本' },
        { id: 1, name: '图文' },
        { id: 2, name: '大图' },
        { id: 3, name: '视频' },
      ],
      // 来源
      sources: [],
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,

      fetchLoading: false,

      //显示来源新增弹窗
      showAddSource: false,

      ratioList: [
        { ratio: `${285 / 3 * 3}:${213 / 3 * 3}`, desc: `模板1 为 ${285 / 3 * 3}px*${213 / 3 * 3}px` },
        { ratio: `${435 / 3 * 3}:${246 / 3 * 3}`, desc: `模板2 为 ${435 / 3 * 3}px*${246 / 3 * 3}px` },
        { ratio: `${957 / 3 * 3}:${538 / 3 * 3}`, desc: `大图类型 为 ${957 / 3 * 3}px*${538 / 3 * 3}px` },
      ]
    }
  },
  watch: {
    'article.type'(newVal, oldVal) {
      /**
       * 三图变大图
       */
      if (newVal === 2 && oldVal === 1) {
        if (this.article.images.length > 1) {
          this.article.images.splice(1)
        }
      }

      // 重置红色的校验
      this.$refs.leftForm.clearValidate(['video', 'images', 'content'])
    },
    articleType() {
      this.$refs.leftForm.clearValidate()
    },
  },
  computed: {
    articleType() {
      return this.article.article_type
    },
  },
  created() {
    this.getAllCategories()
    this.getCityData()
  },
  methods: {
    //刷新列表
    updateSourceList(name) {
      this.getAllSources(name)
    },
    closeAddSource() {
      this.showAddSource = false;
    },
    getCityData(){
      getCityData().then(res=>{
        this.areaData = res.data
      })
    },
    handleChange(e) {
      this.article.region = e.join(',')
    },
    getApi(data) {
      return addClassify(data)
    },
    reValidate(field = '') {
      this.$refs.leftForm.clearValidate()
      if (field)
        this.$refs.leftForm.validateField(field, (valid) => {
          console.log(field, valid, '是否通过校验')
        })
      else {
        // 校验整个表单
        this.$refs.leftForm.validate((valid) => {})
      }
    },
    // 封面图校验
    ImgsPass(rule, value, callback) {
      if (this.article.type === 3) {
        if (value?.thumbnail) {
          // 视频才有缩略图
          callback()
        } else {
          callback(new Error('请上传封面图'))
        }
      } else {
        if (value.length && value) {
          callback()
        } else {
          callback(new Error('请上传封面图'))
        }
      }
    },
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      this.article.files = fileList
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          this.article.files.push({ ...res.data, name: file.file.name })
        })
        .catch(() => {})
    },
    // 获取公众号文章链接
    handleTitleAndCover() {
      this.fetchLoading = true

      getWxPublicArticle({
        url: this.article.wx_url,
      })
        .then((res) => {
          this.article.title = res.data.title
          this.article.images = []
          this.article.images.push({
            url: res.data.cover_image,
            type: 'image',
            id: new Date().getTime(),
          })
          this.$nextTick(() => {
            this.fetchLoading = false
            this.reValidate('title')
            this.reValidate('images')
          })
        })
        .catch((err) => {
          console.log(err)
          this.fetchLoading = false
        })
    },
    /**
     * 监听文章分类级联选择器值变化（点击），收起
     * @param {Object} e
     */
    handleLevelChange(e) {
      // if (this.$refs.levels && this.$refs.levels.getCheckedNodes().length > 0) {
      // let flag = this.$refs.levels.getCheckedNodes()[0].data.isLeaf;
      // if (flag || (!this.$refs.levels.getCheckedNodes()[0].hasChildren)) {
      this.$refs.levels.dropDownVisible = false
      // }
      // }
    },
    getAllCategories() {
      getAllCategories({
        is_tree: 1,
      })
        .then((res) => {
          this.categories = res.data
        })
        .catch((err) => {})
        .finally(() => {
          this.getAllSources()
        })
    },
    /**
     * 新增后立刻帮选
     */
    getAllSources(autoName) {
      getAllSources({
        portal_user_id: this.portal_user_id,
      })
        .then((res) => {
          const { data } = res
          this.sources = data
          if (autoName) {
            this.article.source_id = data.find(d => d.name === autoName)?.id;
          }
        })
        .catch((err) => {})
    },
    /**
     * 富文本编辑器自定义工具栏按钮点击事件
     * @param {Object} e
     */
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    /**
     * 逐一向富文本编辑器插入来自媒体库的图片
     * @param {Array} e 选中的图片数组
     */
    updateImages(e) {
      e.forEach((item) => {
        if (item.type !== 'image') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        if (item.type !== 'video') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        if (item.type !== 'audio') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    /**
     * 设置导入成功的文章内容
     * 设置封面图和标题
     * @param {Object} e
     */
    getImportDetail(e) {
      this.$set(this.article, 'content', e.content)
      this.$refs.editor.$emit('change', e.content)
      this.article.title = e.title
      this.article.images = []
      this.article.images.push({
        url: e.cover_image,
        type: 'image',
        id: new Date().getTime(),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.article-left {
  .title {
    display: flex;
  }

  .media-preview-list {
    display: flex;
    flex-wrap: wrap;

    .img-tips {
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
      margin-top: 10px;
      text-align: center;
    }

    .upload-button,
    .media-preview-item {
      margin-right: 10px;
    }

    .media-preview-item {
      display: flex;
      flex-direction: column;
    }

    .el-image,
    .preview-video {
      width: 194px;
      height: 135px;
      background-color: #c1c1c1;
    }
  }
}
</style>
