import api from "@/base/utils/request";

// 新增或者编辑文章保存
export const saveDetail = data => {
  return api({
    url: '/admin/portal/article/save',
    method: 'post',
    data
  })
}

/**
 * 写文章 - 插件列表
 */
export const getTools = () => {
  return api({
    url: '/admin/portal/article/getFeatures',
    method: 'post'
  })
}
