/***
 * 写文章 - 右侧发布板块
 */

import api from "@/base/utils/request";

/**
 * 获取所有专题
 */
export const getAllSubjects = () => {
  return api({
    url: '/admin/portal/subject/all',
    method: 'post'
  })
}

/**
 * 获取某个专题下的所有栏目
 * @param id
 */
export const getAllColumns = (id) => {
  return api({
    url: '/admin/portal/subject/columns',
    method: 'post',
    data: {id}
  })
}

// 获取架构职务
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};

/**
 * 保存文章预览内容
 * @param data
 */
export const savePreview = (data) => {
  return api({url: '/admin/portal/article/savePreview', method: 'post', data})
}