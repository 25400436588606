/***
 * 写文章 - 右侧 - 工具 - 抽奖
 */
import api from "@/base/utils/request";

/**
 * 抽奖列表
 * @param page
 */
export const getLotteries = (page) => {
  return api({
    url: '/admin/portal/article/writeArticleLottery',
    method: 'post',
    data: {page}
  })
}
