import { render, staticRenderFns } from "./ArticleVote.vue?vue&type=template&id=17ace776&scoped=true&"
import script from "./ArticleVote.vue?vue&type=script&lang=js&"
export * from "./ArticleVote.vue?vue&type=script&lang=js&"
import style0 from "./ArticleVote.vue?vue&type=style&index=0&id=17ace776&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ace776",
  null
  
)

export default component.exports