<template>
  <div class="add-subjects">
    <div class="subject-list">
      <div class="single-subject" v-for="(subject, index) in myValue" :key="index">
        <!--      <el-cascader-->
        <!--          v-model="myValue"-->
        <!--          :props="props"-->
        <!--          :options="subjects"-->
        <!--          :disabled="loading"-->
        <!--          size="medium">-->
        <!--      </el-cascader>-->
        <div class="item flex">
          <!-- 所有专题 -->
          <el-select v-model="myValue[index].subject_id" size="medium"
                     placeholder="请选择专题" @change="getColumns(myValue[index].subject_id, index)">
            <el-option v-for="(s, i) in subjects" :key="s.id" :value="s.id" :label="s.name"></el-option>
          </el-select>
          <!-- 专题对应的所有栏目 -->
          <el-select v-model="myValue[index].column_id" size="medium" style="margin-left: 10px;"
                    placeholder="请选择栏目" @change="updateSelected">
            <template v-if="myValue[index].subject_id && subjects.findIndex(it => it.id === myValue[index].subject_id) !== -1">
              <el-option v-for="(c, i) in subjects[subjects.findIndex(it => it.id === myValue[index].subject_id)].children" :key="c.id"
                         :value="c.id" :label="c.name"></el-option>
            </template>
          </el-select>
          <!-- 点击移除专题 - 栏目 -->
          <div @click="removeLine(index)" class="subject-del-btn flex-center"><i class="el-icon-delete"></i></div>
        </div>
      </div>
    </div>
    <el-button class="subject-add-btn" type="primary" size="small" @click="addLine">添加专题</el-button>
  </div>
</template>

<script>
  import {getAllColumns, getAllSubjects} from "../../api/write-article/article-right/article-publish";

  export default {
    name: "AddSubjects",
    props: {
      value: {
        type: Array
      },
      articleId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        loading: true,
        // props: {
        //   expandTrigger: 'hover',
        //   label: 'name',
        //   value: 'id',
          // multiple: true,
        //   emitPath: false,
        //   lazy: true,
        //   lazyLoad(node, resolve) {
        //     const {level, value} = node;
        //     if (!value) {
        //       resolve([])
        //     } else {
        //       getAllColumns(level === 1 ? value.substring(2) : value).then(res => {
        //         let resData = res.data.map(item => {
        //           return {
        //             id: item.id,
        //             name: item.name,
        //             leaf: level >= 2
        //           }
        //         })
        //         resolve(resData)
        //       }).catch(err => {
        //         resolve([])
        //       })
        //     }
        //   }
        // },
        subjects: [],
        myValue: []
      }
    },
    watch: {
      value: {
         handler(val) {
        // 同步回显专题 - 栏目数据
        this.getSubjects(async () => {
          this.myValue = val;

          for (let k = 0; k < val.length; k++) {

            await this.getColumns(val[k].subject_id, k)
          }
        })
      }, immediate: true, // 立即执行
    }
    },
    created() {
      // 撰写文章，默认添加一行"空"的专题 - 栏目
      if (this.articleId === 0 || this.articleId === '0') {
        this.addLine()
        // 获取所有专题列表数据
        this.getSubjects()
      }
    },
    methods: {
      /**
       * 获取所有专题
       * @param{Function} callback  成功回调
       */
      getSubjects(callback) {
        if (this.subjects.length) {
          callback && callback();
          return;
        }
        this.loading = true;
        getAllSubjects().then(res => {
          this.subjects = res.data.map(item => {
            return {
              id: item.id,
              name: item.name,
              children: []
            }
          });
          this.loading = false;
          callback && callback()
        }).catch(err => {
          this.loading = false;
        })
      },
      /**
       * 修改栏目，触发双向绑定
       */
      updateSelected() {
        this.$emit('input', this.myValue)
      },
      /**
       * 移除专题 - 栏目
       * @param{Number} i 下标
       */
      removeLine(i) {
        this.myValue.splice(i, 1)
      },
      /**
       * 添加专题 - 栏目
       * 同时触发双向绑定
       */
      addLine() {
        this.myValue.push({subject_id: "", column_id: ""})
        this.$emit('input', this.myValue)
      },
      /**
       * 获取某个专题下的所有栏目
       * @param id  专题id
       * @param index 下标
       */
      async getColumns(id, index) {
        if (id === "") return
        let subIndex = this.subjects.findIndex(item => item.id === id)
        // this.myValue[index].column_id = "";

        if (this.subjects[subIndex] && this.subjects[subIndex].children && this.subjects[subIndex].children.length) return

        try {
          let result = await getAllColumns(id)
          if (this.subjects[subIndex]) {
            this.subjects[subIndex].children = result.data || [];
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-subjects {
    .subject-list {
      display: flex;
      flex-direction: column;

      .single-subject {
        display: flex;
        justify-content: space-between;

        .item {
          padding: 5px 0;
        }
      }

      .subject-del-btn {
        width: 30px;
        text-align: right;
        padding: 10px 0;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .subject-add-btn {
      margin-top: 5px;
    }
  }
</style>
