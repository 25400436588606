<!--
 * @Author: mulingyuer
 * @Date: 2021-04-12 10:03:13
 * @LastEditTime: 2021-04-12 10:48:44
 * @LastEditors: mulingyuer
 * @Description: 图文选项
 * @FilePath: \saas-admin-vue\src\modules\vote\components\vote-list\ImgTextOption.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="img-text-option">
    <div class="add">
      <el-button type="primary" size="mini" @click="onAdd">添加选项</el-button>
    </div>
    <div class="add-content">
      <div class="item" v-for="(item,index) in imgTextArr" :key="`item-${index}}`">
        <div class="left">
          <single-media-wall v-model="item.avatar" :width="80" :height="80" :on-change="updata" />
        </div>
        <div class="right">
          <el-input v-model="item.name" @blur="updata"></el-input>
          <span class="close el-icon-error" @click="onClose(index)"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

  export default {
  model: {
    prop: "data",
    event: "handleData",
  },
  props: {
    //数据
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imgTextArr: [{ avatar: "", name: "" }],
    };
  },
  methods: {
    //新增
    onAdd() {
      this.imgTextArr.push({ avatar: "", name: "" });
      this.updata();
    },
    //删除
    onClose(index) {
      this.imgTextArr.splice(index, 1);
      this.updata();
    },
    //初始化数据
    initData() {
      if (this.data.length) {
        this.imgTextArr = this.data.map((item) => {
          return {
            avatar: item.avatar || "",
            name: item.name !== "" ? item.name : "",
          };
        });
      }
    },
    //更新数据
    updata() {
      this.$emit("handleData", this.imgTextArr);
    },
    onAvatarChange() {
      console.log(111);
    },
  },
  created() {
    this.initData();
    this.updata();
  },
  watch: {
    data() {
      this.initData();
    },
  },
  components: {
    SingleMediaWall,
  },
};
</script>
<style lang="scss" scoped>
.img-text-option {
  .add {
    margin-bottom: 10px;
  }
  .add-content {
    .item {
      display: flex;
      align-items: flex-start;
      & + .item {
        margin-top: 10px;
      }
      .left {
        flex-shrink: 0;
        margin-right: 10px;
      }
      .right {
        flex-grow: 1;
        display: flex;
        align-items: center;
        .close {
          margin-left: 10px;
          font-size: 20px;
          color: #999;
          padding: 5px;
          cursor: pointer;
          &:hover,
          &:active {
            color: #3576ff;
          }
          &:active {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
