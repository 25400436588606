<template>
  <el-dialog v-if="value" class="vote-create-dialog dialog-vertical" width="600px" title="新建投票" :visible="value" @close="close">
    <div class="content" v-loading="loading" style="overflow-y: auto; max-height: 60vh;">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="75px" class="medium-form">
        <el-form-item label="标题：" prop="title">
          <el-input v-focus v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="投票类型：" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">文字</el-radio>
            <el-radio :label="1">图文</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选项：" prop="options">
          <text-option v-if="form.type===0" v-model="form.options" />
          <img-text-option v-else-if="form.type===1" v-model="form.options" />
        </el-form-item>
        <el-form-item label="投票方式：" prop="more">
          <el-radio-group v-model="form.more">
            <el-radio :label="0">单选</el-radio>
            <el-radio :label="1">多选</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="投票时间：" prop="time">
          <DateTimePicker v-model="form.time" />
        </el-form-item>
        <el-form-item label="投票票数：" prop="limit_type" class="limit">
          <el-radio-group v-model="form.limit_type">
            <el-radio :label="0">每人每天可投<el-input style="width: 80px; margin: 0 10px;" v-model="form.daily_limit" :disabled="form.limit_type===1">
            </el-input>票</el-radio>
            <el-radio :label="1">每人可投<el-input style="width: 80px; margin: 0 10px;" v-model="form.total_limit" :disabled="form.limit_type===0">
            </el-input>票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="投票结果：" prop="is_open">
          <el-radio-group v-model="form.is_open">
            <el-radio :label="1">所有人可见</el-radio>
            <el-radio :label="0">投票后可见</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="confirm('ruleForm')">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import DateTimePicker from "../../../../../base/components/Form/DateTimePicker";
  import TextOption from "./TextOption";
  import ImgTextOption from "./ImgTextOption";
  import {voteSave} from "../../../api/write-article/article-right/article-vote";

  export default {
    name: "VoteCreateDialog",
    components: {ImgTextOption, TextOption, DateTimePicker},
    props: {
      value: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        loading: false,
        form: {
          id: 0, //0为新增，其他id值为编辑
          title: "", //标题
          type: 0, //类型 0：文字 1：图文
          options: [], //选项
          more: 0, //投票方式 0:单选，1：多选
          time: [-1, -1], //开始时间 & 结束时间
          // start_time: -1, //开始时间
          // end_time: -1, //结束时间
          limit_type: 0, //投票限制方式：0每天可投次数限制，1限制总投票次数
          daily_limit: 1, //每天的投票次数
          total_limit: 1, //总的投票次数
          is_open: 1, //投票结果 0:投票后可见，1：所有人可见
        },
        rules: {},
      }
    },
    methods: {
      close() {
        this.$refs.ruleForm.resetFields();
        this.form.id = 0
        this.form.options = []
        this.form.time = [-1, -1]
        this.form.daily_limit = 1
        this.form.total_limit = 1
        this.$emit('input', false);
      },
      confirm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            const postData = this.initSaveData();
            voteSave(postData)
              .then((res) => {
                const { msg } = res;
                this.$message.success(msg);
                this.close();
                this.$emit('refresh');
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
      },
      //保存时数据转换
      initSaveData() {
        const newData = Object.assign({}, this.form);
        // 投票类型
        delete newData.limit_type;
        if (this.form.limit_type === 0) {
          newData.is_daily_limit = 1;
          newData.is_total_limit = 0;
        } else if (this.form.limit_type === 1) {
          newData.is_daily_limit = 0;
          newData.is_total_limit = 1;
        }
        //投票时间
        delete newData.time;
        newData.start_time = this.form.time[0];
        newData.end_time = this.form.time[1];
        return newData;
      },
    },
  }
</script>

<style scoped>

</style>
