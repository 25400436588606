<template>
  <div class="article-vote">
    <div class="flex-between">
      <label class="sub-label">选择投票项目</label>
      <el-button type="text" @click="$emit('update-show', true)">新建投票</el-button>
    </div>
    <div class="vote-search-container flex-col" v-if="list.length">
      <div class="search">
        <i class="el-icon-search" @click="searchVotes"></i>
        <input type="text" placeholder="请输入标题关键字"
               @keyup.enter="searchVotes" v-model="keyword"
               @change="$emit('update-keyword', keyword)">
      </div>
      <div class="vote-list" style="max-height: 400px; overflow:auto;">
        <div class="search-item" v-for="(item, index) in list">
          <span>{{item.title}}</span>
          <el-button type="text" :disabled="options.choose.findIndex(it => it.id === item.id) !== -1"
                     @click="addVoteToArticle(item)">选择</el-button>
        </div>
      </div>
    </div>
    <div class="no-template" v-if="!keyword && !list.length">
      <div class="no-template-content">
        <img src="../../assets/images/no-template.png"/>
        <p>暂时没有内容</p>
      </div>
    </div>
    <div style="margin-top: 40px;" v-for="(voteData, ii) in options.choose">
      <div class="flex-between">
        <label class="sub-label">已选择投票</label>
        <el-button type="text" @click="delVoteToArticle(ii)">删除</el-button>
      </div>
      <div class="vote-container" >
        <div class="vote-title" style="margin-bottom: 5px;">{{voteData.title}}</div>
        <div class="vote-config flex">
          <span>{{voteData.more_text}}</span>
          <span style="margin-left: 20px;">{{voteData.is_open_text}}</span>
          <span style="margin-left: 20px;">{{voteData.total}}人参与</span>
        </div>
        <!-- 带图片的 -->
        <div class="vote-item-container" v-if="true || voteData.type == 1 ">
          <div class="vote-item" v-for="(item,index) in voteData.my_options">
            <img v-if="voteData.type === 1" :src="item.item.avatar" alt=""  style="margin-right:10px;" width="80px" height="80px">
            <div style="flex:1;height:100%;">
              <p class="vote-name" style="margin-bottom: 18px;">{{item.item.name}}</p>
              <div class="vote-num flex-between" style="margin-bottom: 5px">
                <span >{{item.record_total}}票</span>
                <span >{{item.ratio.toString().substr(0, 5)}}%</span>
              </div>
              <el-progress :percentage="item.ratio" :show-text="false"></el-progress>
            </div>
          </div>
        </div>
        <!-- 文字的（废弃） -->
        <div class="vote-item-container" v-if="false && voteData.type == 0 ">
          <div class="vote-item" v-for="(item,index) in voteData.my_options">
            <div style="flex:1">
              <p class="vote-name" style="margin-bottom:10px">{{item.item.name}}</p>
<!--              <div class="vote-num flex-between">-->
<!--                <div class="progress-bar" >-->
<!--                  <div style="height:100%;background-color:#4680FF;" :style="{ width: item.ratio } "></div>-->
<!--                </div>-->
<!--                <span style="margin-left:10px">{{item.record_total}}票</span>-->
<!--                <span style="margin-left:10px">{{item.ratio}}</span>-->
<!--              </div>-->
              <div class="flex flex-between">
                <el-progress style="width: 80%" :text-inside="true" :stroke-width="26" class="flex-align-center" :percentage="Number(item.ratio.toString().substr(0, 4))" :show-text="true" />
                <span style="margin-left:10px">{{item.record_total}}票</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {searchVotes} from "../../api/write-article/article-right/article-vote";

  export default {
    name: "ArticleVote",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      list: Array,
      options: Object
    },
    data() {
      return {
        keyword: ''
      }
    },
    methods: {
      searchVotes() {
        if (!this.keyword) return;
        this.loading = true;
        searchVotes(this.keyword).then(res => {
          let {data} = res;
          this.$emit('update-list', data)
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      /**
       * 投票显示在文章正文之下
       * @param vote
       */
      addVoteToArticle(vote) {
        // this.options.choose.push(vote)
        this.$emit('add-vote', vote)
      },
      delVoteToArticle(i) {
        // this.options.choose.splice(i, 1)
        this.$emit('del-vote', i)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vote-search-container {
    background-color: rgba(250, 250, 250, 1);
    overflow: hidden;

    .search {
      margin: 10px;
      height: 40px;
      background: rgba(241, 241, 241, 1);
      border-radius: 4px;
      padding-left: 10px;
      display: flex;
      align-items: center;

      input {
        border: none;
        background-color: transparent;
        margin: 0 10px;
        outline: none;
      }
    }

    .search-item {
      height: 40px;
      padding: 0 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .search-item:hover {
      background-color: #F2F8FE;
    }
  }
</style>
