<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 18:14:58
 * @LastEditTime: 2021-04-12 14:43:39
 * @LastEditors: mulingyuer
 * @Description: 文字选项
 * @FilePath: \saas-admin-vue\src\modules\vote\components\vote-list\TextOption.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="text-option">
    <div class="add">
      <el-button type="primary" size="mini" @click="onAdd">添加选项</el-button>
    </div>
    <div class="add-content">
      <div class="item" v-for="(item,index) in textArr" :key="`item-${index}}`">
        <el-input v-model="item.name" @blur="updata"></el-input>
        <span class="close el-icon-error" @click="onClose(index)"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: "data",
    event: "handleData",
  },
  props: {
    //数据
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      textArr: [{ name: "" }],
    };
  },
  methods: {
    //新增
    onAdd() {
      this.textArr.push({ name: "" });
      this.updata();
    },
    //删除
    onClose(index) {
      this.textArr.splice(index, 1);
      this.updata();
    },
    //初始化数据
    initData() {
      if (this.data.length) {
        this.textArr = this.data.map((item) => {
          return { name: item.name !== "" ? item.name : "" };
        });
      }
    },
    //更新数据
    updata() {
      this.$emit("handleData", this.textArr);
    },
  },
  created() {
    this.initData();
    this.updata();
  },
  watch: {
    data() {
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.text-option {
  .add {
    margin-bottom: 10px;
  }
  .add-content {
    .item {
      & + .item {
        margin-top: 10px;
      }

      .close {
        display: inline-block;
        margin-left: 10px;
        font-size: 20px;
        color: #999;
        padding: 5px;
        cursor: pointer;
        &:hover,
        &:active {
          color: #3576ff;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
