<template>
  <div class="article-signup">
    <div class="flex-between">
      <label class="sub-label">选择报名项目</label>
    </div>
    <div id="signup-list" style="max-height: 100%; overflow-y: unset;">
      <div class="no-template" v-if="noResult">
        <div class="no-template-content">
          <img src="../../assets/images/no-template.png"/>
          <p>暂时没有内容</p>
        </div>
      </div>
      <ul style="list-style: none; padding: 0; " v-if="list.length">
        <li v-for="(li, index) in list" :key="index">
          <div class="search-item flex-col" style="height: auto; ">
            <div style="margin-bottom: 20px;"><p style="color: #333;">{{li.title}}</p></div>
            <div style="margin-bottom: 20px; color: #666;font-size: 12px;">{{li.start_time}} ~ {{li.end_time}}</div>
            <div>
              <!-- <el-button type="text" style="margin-right: 10px;" @click="previewLink(li.link)">预览</el-button>-->
              <el-button type="text" style="margin-right: 10px;" @click="addSignup(li)">选择</el-button>
            </div>
          </div>
          <div class="separate-line"></div>
        </li>
      </ul>
      <el-button v-if="!noMore" type="text" style="width: 100%;" @click="$emit('load-more')">点击查看更多</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ArticleSignup",
    props: {
      page: Number,
      list: {
        type: Array,
        default: () => []
      },
      noMore: {
        type: Boolean,
        default: false
      },
      noResult: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {

      }
    },
    methods: {
      addSignup(obj) {
        let defaultCover = window.serverConfig.VUE_APP_ADMINURL + '/signup/images/admin/sign_default.png';
        let cover = (obj.images && obj.images[0] ? obj.images[0] : defaultCover) + '?h=122&fit=max';
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p style="">&nbsp;
<span style="box-sizing: border-box; display:block;margin: 0 auto; max-width: 336px;border: 1px solid #e7e7e7;padding: 15px; 18px 18px;background-color: #F9FAF9;border-radius:4px;">
<a data-id="${obj.id}" data-type="signup" href="${obj.link}" style="display: flex; flex-direction: column;font-weight:bold; line-height: 24px; color: #272F3C;position: relative;">${obj.title}<br/>
<img class="no-preview" src="${cover}" style="width: 100%; height: 122px !important;max-width: 300px; object-fit: cover; margin-top: 11px;"/>
</a></span>&nbsp;</p>`
        );
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
