<template>
  <div class="article-template" v-loading="loading">
    <div class="search-bar">
      <div class="search-area">
        <i class="el-icon-search"></i>
        <input @keyup.enter="searchTemplates" v-model="keyword" placeholder="关键词"/>
      </div>
      <div class="search-label-list">
        <div class="search-label cursor-pointer" @click="getRecommends" :class="{'active': type === 0}">推荐</div>
        <div class="search-label" @click="getCollectedTemplates(1)" :class="{'active': type === 1, 'cursor-pointer': type !== 1}">收藏</div>
        <div class="search-label" @click="getCustomTemplates(1)" :class="{'active': type === 2, 'cursor-pointer': type !== 2}">个人</div>
      </div>
    </div>
    <div class="category-line" v-if="type < 1">
      <div class="category-list">
        <div class="category-item" :class="{'active': category_id === -1}" @click="getTmplList({page: 1, id: -1})">全部</div>
        <div class="category-item" :class="{'active': category_id === c.id}" @click="getTmplList({page: 1, id: c.id})"
             v-for="(c, i) in categoryList" :key="c.id">{{c.name}}</div>
      </div>
      <el-dropdown v-if="categories.length >= 5" @command="handleDropdown">
        <el-button type="text">更多</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="c.id" v-for="(c, i) in dropdownList" :key="c.id">{{c.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="no-template" v-if="!list || !list.length">
      <div class="no-template-content">
        <img src="../../assets/images/no-template.png"/>
        <p>暂时没有内容</p>
      </div>
    </div>
    <!-- 模板列表 -->
    <div class="template-list flex-col">
      <div class="template-item" v-for="(t, i) in list" :key="t.id">
        <el-divider></el-divider>
        <div class="template-item-box" @click="addToArticleContent(i)">
          <div class="template-item-content" v-html="t.content"></div>
          <div class="template-item-like" v-if="type !== 2" @click.stop="collectTemplate(t, i)">
            <el-tooltip content="收藏" placement="top" v-if="!t.is_collect">
              <img src="../../assets/images/template/like.png"/>
            </el-tooltip>
            <el-tooltip content="已收藏" placement="top" v-if="t.is_collect">
              <img src="../../assets/images/template/liked.png"/>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: 查看更多 -->
    <div class="more-btn" v-if="list.length && showMore">
      <el-button type="text" @click="loadMoreTemplates">点击查看更多</el-button>
    </div>
  </div>
</template>

<script>
  import {
    collectTemplate,
    getCollectedTmplList,
    getCustomTmplList,
    getTmplCategories,
    getTmplList
  } from "../../api/article-template";

  export default {
    name: "ArticleTemplate",
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        loading: true,
        page: 0,
        content: "",
        keyword: "",
        categories: [],
        category_id: -1,
        is_recommend: true,
        list: [],
        // 0：推荐，1：收藏，2：个人（自定义）
        type: 0,
        // 是否显示"加载更多"
        showMore: false,
      }
    },
    computed: {
      categoryList() {
        return this.categories.filter((item, index) => index < 5);
      },
      dropdownList() {
        return this.categories.filter((item, index) => index >= 5);
      }
    },
    watch: {
      active(newValue, oldValue) {
        if (newValue) {
          if (!this.categories.length) {
            this.getTmplCategories()
            this.getRecommends()
          }
        }
      }
    },
    created() {

    },
    methods: {
      handleDropdown(e) {

      },
      addToArticleContent(i) {
        this.content = this.list[i].content;
        window.tinymce.execCommand("mceReplaceContent",
          false, this.content + "<br/>")
      },
      loadMoreTemplates() {
        if (this.type === 2) {
          // 个人
          this.getCustomTemplates(this.page + 1);
        } else if (this.type === 1) {
          // 收藏
          this.getCollectedTemplates(this.page + 1);
        }
      },
      collectTemplate(t, i) {
        this.loading = true;
        collectTemplate({
          is_collect: !t.is_collect,
          article_tmpl_id: t.id
        }).then(res => {
          this.$message.success(res.msg);
          t.is_collect = !t.is_collect;
          // 如果当前是收藏列表，那么移除被取消的收藏
          if (!t.is_collect && this.type === 1) this.list.splice(i, 1);
        }).catch(err => {

        }).finally(() => {
          this.loading = false;
        })
      },
      searchTemplates() {
        if (this.type > 0) {
          this.page = 0;
          this.loadMoreTemplates();
        } else {
          this.getTmplList({page: 1})
        }
      },
      getTmplCategories() {
        this.loading = true;
        getTmplCategories().then(res => {
          this.categories = res.data;
          // if (res.data.length) this.category_id = res.data[0].id;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      getTmplList({page, id}) {
        this.loading = true;
        if (this.type > 0) this.type = -1;
        if (page) this.page = page;
        if (id) this.category_id = id;
        getTmplList({
          page: page || this.page,
          page_size: 10,
          tmpl_category_id: id || this.category_id,
          is_recommend: this.is_recommend,
          keyword: this.keyword
        }).then(res => {
          if (page === 1) {
            this.list = res.data;
          } else {
            this.list.push(...res.data);
          }
          if (page) this.page = page;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      getRecommends() {
        this.is_recommend = this.is_recommend ? 0 : 1;
        // this.is_recommend = 1;
        this.type = this.is_recommend ? 0 : -1;
        this.getTmplList({page: 1});
      },
      getCollectedTemplates(page) {
        // if (this.type === 1) {
        //   this.type = -1;
        //   return;
        // }
        this.type = 1;
        this.category_id = -1;
        this.is_recommend = 0;
        if (page === 1) this.list = [];
        this.loading = true;
        getCollectedTmplList({
          // TODO: 搜索关键词等后端实现再来优化
          page: page || this.page,
          page_size: 10
        }).then(res => {
          if (page === 1) {
            this.list = res.data;
          } else {
            this.list.push(...res.data);
          }
          if (page) this.page = page;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      getCustomTemplates(page) {
        this.type = 2;
        this.category_id = -1;
        this.is_recommend = 0;
        if (page === 1) this.list = [];
        this.loading = true;
        getCustomTmplList({
          page: page || this.page,
          keyword: this.keyword
        }).then(res => {
          if (page === 1) {
            this.list = res.data;
          } else {
            this.list.push(...res.data);
          }
          if (page) this.page = page;
          // page_size: 10 是否显示"加载更多"
          this.showMore = this.list.length === 10;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .article-template {
    padding: 0 $space 40px;
  }
</style>
