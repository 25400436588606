<template>
  <div class="public-list">
    <div class="public-item" style="border: none">
      <div class="public-item-content button-group">
        <!-- 状态(0：草稿，1：已发布，2：待审核，3：不通过，4：待发布，5：不采用，6：定时发布，7：已撤回) -->
        <el-button
          @click="$emit('save', 2)"
          type="primary"
          v-if="
            post_check_enable === 1 && (!article.id || article.status === 0)
          "
          size="small"
          >提交
        </el-button>
        <el-button
          @click="$emit('save', article.status)"
          type="primary"
          v-if="article.id"
          size="small"
          >保存
        </el-button>
        <el-button
          v-if="showTimeControl || article.status === 0"
          @click="handlePublish"
          type="primary"
          size="small"
        >
          发布
        </el-button>
        <!--        <el-button @click="$emit('time-post')" v-if="post_check_enable === 0 || article.status === 4 || article.status === 5" size="small">定时发布-->
        <!--        </el-button>-->
        <el-button
          @click="$emit('save', 0)"
          type="plain"
          v-if="!article.id || article.status === 7"
          size="small"
          >存为草稿
        </el-button>
        <el-button
          :loading="previewLoading"
          @click="savePreview"
          type="warning"
          size="small"
          >预览</el-button
        >
        <el-form v-if="showTimeControl" class="small-form" style="width: 100%">
          <el-form-item style="margin-bottom: 5px">
            <el-radio-group v-model="isSetTime">
              <el-radio :label="0">立即发送</el-radio>
              <el-radio :label="1">定时发送</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="isSetTime" style="margin-bottom: 5px">
            <el-date-picker
              size="medium"
              v-model="postTime"
              type="datetime"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              placeholder="选择日期时间"
              time-arrow-control
              style="max-width: 100%; width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--    <div class="public-item">-->
    <!--      <h3 class="public-item-title">平台</h3>-->
    <!--      <div class="public-item-content">-->
    <!--        <el-checkbox-group>-->
    <!--          <el-checkbox :label="0">web</el-checkbox>-->
    <!--          <el-checkbox :label="1">H5</el-checkbox>-->
    <!--          <el-checkbox :label="2">小程序-->
    <!--            <el-tooltip class="item" effect="dark"-->
    <!--                        content="需勾选H5才可在小程序端查看文章详情" placement="top">-->
    <!--              <i class="el-icon-question"></i>-->
    <!--            </el-tooltip>-->
    <!--          </el-checkbox>-->
    <!--        </el-checkbox-group>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="public-item">
      <h3 class="public-item-title">查看权限</h3>
      <div class="public-item-content">
        <el-form class="small-form" label-width="110px">
          <el-form-item label="全部会员可见：" required>
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="article.member_limit"
            />
            <div class="info">
              <i
                class="el-icon-info"
              />开启后全部会员可见（包括后续新增的组织架构、会内职务的会员）
            </div>
          </el-form-item>
          <el-form-item
            v-show="!article.member_limit"
            label="部分职务可见："
            required
          >
            <template v-if="positionOptions">
              <el-cascader
                style="width: calc(100%)"
                v-model="article.view_positions"
                placeholder="请选择架构职位"
                :options="positionOptions"
                :props="props"
                clearable
                collapse-tags
                @change="positionSelected"
              >
              </el-cascader>
            </template>
            <div class="info">
              <i class="el-icon-info" />设置特定的组织架构、会内职务可见
            </div>
          </el-form-item>
          <el-form-item label="非会员可见：" required>
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="article.non_member_limit"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="public-item">
      <h3 class="public-item-title">文章参数</h3>
      <div class="public-item-content article-content-statistics">
        <div class="article-content-statistics-item">
          <div class="article-content-statistics-item-name">字数</div>
          <div class="article-content-statistics-item-value">
            {{ statistics.text_length }}
          </div>
        </div>
        <div class="article-content-statistics-item">
          <div class="article-content-statistics-item-name">图片</div>
          <div class="article-content-statistics-item-value">
            {{ statistics.img_length }}
          </div>
        </div>
        <div class="article-content-statistics-item">
          <div class="article-content-statistics-item-name">视频</div>
          <div class="article-content-statistics-item-value">
            {{ statistics.video_length }}
          </div>
        </div>
        <div class="article-content-statistics-item">
          <div class="article-content-statistics-item-name">音频</div>
          <div class="article-content-statistics-item-value">
            {{ statistics.audio_length }}
          </div>
        </div>
      </div>
    </div>
    <div class="public-item flex-between">
      <h3 class="public-item-title">评论开关</h3>
      <div class="public-item-content" style="margin-top: 18px">
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="article.commentable"
        />
      </div>
    </div>
<!--    <div class="public-item">-->
<!--      <h3 class="public-item-title">标签</h3>-->
<!--      <div class="public-item-content">-->
<!--        <add-tags v-model="article.tags" />-->
<!--      </div>-->
<!--    </div>-->
    <div class="public-item">
      <h3 class="public-item-title">专题</h3>
      <div class="public-item-content">
        <add-subjects
          v-model="article.subjects"
          :article-id="article.id"
        ></add-subjects>
      </div>
    </div>
    <div class="public-item">
      <div class="flex-between">
        <h3 class="public-item-title">置顶</h3>
        <el-switch
          style="margin: 18px 20px 0 0"
          :active-value="2"
          :inactive-value="0"
          v-model="setIsTop"
          @change="toggleTop"
        ></el-switch>
      </div>
      <div class="public-item-content" v-if="setIsTop">
        <el-form size="medium" label-width="50px" label-position="top">
          <el-form-item label="位置">
            <el-radio-group v-model="article.top_position">
              <!--              <el-radio :label="0">不置顶</el-radio>-->
              <el-radio :label="3">全部分类页</el-radio>
              <el-radio :label="2">单一分类页</el-radio>
            </el-radio-group>
            <p class="info">
              全部分类页即文章将在所有分类中置顶，单一分类即文章只在所属分类下置顶
            </p>
          </el-form-item>
          <el-form-item label="时间">
            <date-time-picker v-model="topRange"></date-time-picker>
          </el-form-item>
          <!--          <el-form-item label="开始">-->
          <!--            <DatePicker v-model="article.top_start_time" style="width: auto;" placeholder="请选择日期"-->
          <!--                        type="datetime" format="yyyy-MM-dd HH:mm"></DatePicker>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="结束">-->
          <!--            <DatePicker v-model="article.top_end_time" style="width: auto;" placeholder="请选择日期"-->
          <!--                        type="datetime" format="yyyy-MM-dd HH:mm"></DatePicker>-->
          <!--          </el-form-item>-->
        </el-form>
      </div>
    </div>

    <!-- 预览 -->
    <article-viewer v-model="preview.show" :options="preview.data" />
  </div>
</template>

<script>
import ArticleViewer from '../../../../base/components/Preview/ArticleViewer'
// import AddTags from './Tag/AddTags'
import DatePicker from '../../../../base/components/Form/DatePicker'
import DateTimePicker from '../../../../base/components/Form/DateTimePicker'
import AddSubjects from './AddSubjects'
import {
  positionLevel,
  savePreview,
} from '../../api/write-article/article-right/article-publish'
import { toShortTimestamp } from '@/base/utils/tool'
import previewArticle from '../../mixins/previewArticle'

export default {
  name: 'ArticlePublish',
  components: {
    AddSubjects,
    DateTimePicker,
    DatePicker,
    // AddTags,
    ArticleViewer,
  },
  mixins: [previewArticle],
  props: {
    article: Object,
    statistics: Object,
    // 是否开启审核机制，不开启则新增编辑都直接发布到文章列表
    post_check_enable: Number,
  },
  data() {
    return {
      isSetTime: 0,
      postTime: '',
      // selectedPositions: [],
      topRange: ['', ''],
      setIsTop: 0,
      positionOptions: [],
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'position',
        emitPath: false,
        expandTrigger: 'hover',
      },
      previewLoading: false,
    }
  },
  computed: {
    showTimeControl() {
      const article = this.article
      const post_check_enable = this.post_check_enable
      return (
        post_check_enable === 0 || article.status === 4 || article.status === 5
      )
    },
  },
  watch: {
    'article.top_start_time'(val) {
      this.topRange = [val, this.article.top_end_time]
    },
    'article.top_end_time'(val) {
      this.topRange = [this.article.top_start_time, val]
    },
    topRange(val) {
      this.$emit('update-top', val)
    },
    article(val) {
      this.setIsTop = val.top_position !== 0 ? 2 : 0
    },
    positionOptions(val) {
      this.positionSelected(this.article.view_positions)
    },
    // 'article.top_position'(newValue, oldValue) {
    //   if (newValue > 0) {
    //     switch (newValue) {
    //       case 3:
    //         this.selectedPositions = [1, 2];
    //         break;
    //       case 2:
    //         this.selectedPositions = [2];
    //         break;
    //       case 1:
    //         this.selectedPositions = [1];
    //         break;
    //       default:
    //         this.selectedPositions = [];
    //     }
    //   }
    // },
    // selectedPositions(val) {
    //   this.$emit('update-positions', val)
    // }
  },
  created() {
    this.setIsTop = this.article.top_position !== 0 ? 2 : 0
    this.getPositionLevel()
  },
  methods: {
    /**
     *  构造提交保存接口的数据
     *  用于预览
     * */
    getNowArticlePreviewData() {
      let data = JSON.parse(JSON.stringify(this.article))
      /* 标签格式为 id 数组 */
      let tagList = []
      if (data.tags.length > 5) {
        // this.$message.error('标签不能超过五个')
      } else if (data.tags.length > 0) {
        tagList = data.tags.map((item) => item.id)
      }

      return {
        id: data.id,
        type: data.type,
        /* 标签 */
        tags: tagList,
        title: data.title,

        images:
          data.images && data.images.length
            ? JSON.stringify(data.images)
            : '[]',
        video: JSON.stringify(data.video),

        summary: data.summary,
        content: data.content,
        source_id: data.source_id,
        post_time: data.post_time,
        category_id: data.category_id,

        // files: this.article.files.map((el) => el.id),
        opaque_id: data.opaque_id
      }
    },
    savePreview() {
      this.previewLoading = true
      const postData = this.getNowArticlePreviewData()
      savePreview({
        ...postData,
      })
        .then(() => {
          // this.toPreviewPage(postData.opaque_id || 0, 'y')
          this.previewArticle({...postData, hash_id: postData.opaque_id || 0}, 'y')
        })
        .catch(() => {})
        .finally(() => {
          this.previewLoading = false
        })
    },
    handlePublish() {
      if (this.isSetTime === 0) {
        this.article.post_time = 0
        this.$emit('save', 1)
        this.postTime = ''
      } else {
        if (!this.postTime) {
          this.$message.error('请选择定时发布日期时间')
          return
        }
        this.article.post_time = toShortTimestamp(this.postTime)
        this.$emit('save', 6)
        this.postTime = ''
      }
    },
    positionSelected(e) {
      let positions = []
      this.positionOptions.forEach((item1) => {
        item1.position.forEach((item2) => {
          if (e.includes(item2.id)) {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          }
        })
      })
      this.selectablePositions = positions
    },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({
        is_list_tree: 0,
        is_show_all: 0,
        hashid_enable: 0,
        data_auth_filter: 0,
      })
        .then((res) => {
          this.positionOptions = res.data
        })
        .catch(() => {})
    },
    /**
     * 切换置顶开关
     */
    toggleTop() {
      this.article.top_position = this.setIsTop
    },
  },
}
</script>

<style lang="scss" scoped>
.public-list {
  padding-bottom: 40px;

  .public-item {
    padding-bottom: $space;
    border-top: 1px solid #f1f1f1;

    .public-item-title {
      font-size: 18px;
      font-weight: 500;
      color: #303133;
      line-height: 25px;
      text-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
      margin-bottom: 7px;
      padding: 24px 20px 0;
    }

    .public-item-content {
      padding: 0 $space;
    }
  }

  .article-content-statistics {
    display: flex;
    flex-direction: column;
    /*padding-right: 10px;*/

    .article-content-statistics-item {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #909399;
      line-height: 32px;
      text-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    .el-button {
      margin-right: 10px;
      margin-bottom: 10px;

      + .el-button {
        margin-left: 0;
      }
    }
  }

  .info {
    font-size: 12px;
    line-height: 16px;
    color: #c0c4cc;
  }

  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    /*width: 380px;*/
  }
}
</style>
