import api from "@/base/utils/request";

/**
 * 报名列表
 * @param page
 */
export const getSigns = (page) => {
  return api({
    url: '/admin/portal/article/writeArticleSignup',
    method: 'post',
    data: {page}
  })
}
