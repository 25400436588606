<template>
    <transition name="moveR">
  <div id="write-article-right" class="article-right" v-show="!hide">
      <div class="right-close" v-if="!hide" @click="toggleRight">&#187;</div>
      <div class="right-placeholder flex-col">
        <div class="my-tabs" style="margin-bottom: 30px;">
          <div class="my-tab-item" :class="[tabName === 'one' ? 'active':'']" @click="tabName = 'one'">发布</div>
          <div class="my-tab-item" :class="[tabName === 'three' ? 'active':'']" @click="tabName = 'three'">模板</div>
          <div class="my-tab-item" :class="[tabName === 'two' ? 'active':'']" @click="tabName = 'two'">工具</div>
          <div class="indicator" :style="{left: `calc(100% / 3 * ${tabName === 'one' ? 0 : (tabName === 'three' ? 1 : 2)})`}">
            <div class="inner"></div>
          </div>
        </div>
        <div class="my-tabs-content flex-col">
          <div class="tab-content flex-col" v-show="tabName === 'one'">
            <article-publish :article="article"
                             :statistics="article_content"
                             :post_check_enable="post_check_enable"
                             @preview="$emit('preview')"
                             @time-post="$emit('time-post')"
                             @save="$emit('save', $event)"
                             @update-top="updateTop"
            />
          </div>
          <div v-loading="loading" class="tab-content flex-col" style="padding: 0 0px; margin-top: -30px;" v-show="tabName === 'two'">
            <el-tabs tab-position="left" type="card" style="margin-left: 0px; flex: 1;" @tab-click="handleClickTool">
              <el-tab-pane :label="t.name" v-for="(t, tI) in toolList.filter(tool => tool.is_show)" :key="t.name">
                <!-- 图标、名称 -->
                <span class="tab-label flex-center" slot="label" :title="toolDict[t.name]">
                  <img v-show="t.name === 'vote'" width="28" src="../../assets/images/icon_vote_no@2x.png"/>
                  <img v-show="t.name === 'lottery'" width="28" src="../../assets/images/lottery.png"/>
                  <img v-show="t.name === 'signup'" width="28" src="../../assets/images/sign2.png"/>
                  <img v-show="t.name === 'opinion'" width="28" src="../../assets/images/opinion.png"/>
                </span>
                <div class="flex-between" style="margin-bottom: 10px;">
                  <h3 class="tool-name">{{toolDict[t.name]}}</h3>
                  <el-switch v-if="t.name === 'vote'" v-model="voteOptions.is_vote"
                             :active-value="1" :inactive-value="0"/>
                  <el-switch v-if="t.name === 'opinion'" v-model="article.opinion_status"
                             :active-value="1" :inactive-value="0"/>
                </div> 
                <template v-if="t.name === 'vote' && voteOptions.is_vote"> 
                  <article-vote :list="vote.list"
                                :options="voteOptions"
                                @add-vote="voteOptions.choose.push($event)"
                                @del-vote="voteOptions.choose.splice($event, 1)"
                                @update-keyword="vote.keyword = $event"
                                @update-show="vote.show = $event"
                                @update-list="vote.list = $event"
                  />
                </template>
                <div v-show="t.name === 'signup'">
                  <article-signup :list="sign.list"
                                  :no-more="sign.no_more"
                                  :no-result="sign.no_result"
                                  @load-more="getSignList()"/>
                </div>
                <div v-show="t.name === 'lottery'">
                  <article-lottery :list="lottery.list"
                                   :no-more="lottery.no_more"
                                   :no-result="lottery.no_result"
                                   @load-more="getLotteryList()"
                                   @preview="previewLink($event)"
                  />
                </div>
              </el-tab-pane>
              <div class="no-template" v-if="!toolList || !toolList.length">
                <div class="no-template-content">
                  <img src="../../assets/images/no-template.png"/>
                  <p>暂时没有内容</p>
                </div>
              </div>
            </el-tabs>
          </div>
          <div class="tab-content flex-col" v-show="tabName === 'three'">
            <article-template :active="tabName === 'three'"/>
          </div>
        </div>
      </div>
    <h5-viewer v-model="preview.show" :src="preview.src"/>
    <vote-create-dialog v-model="vote.show" @refresh="$emit('refresh-vote-list')"/>
  </div>
    </transition>
</template>

<script>
  import Tinymce from "@/base/components/Editor/Tinymce";
  import DatePicker from "../../../../base/components/Form/DatePicker";
  import {getLotteries} from "../../api/write-article/article-right/article-lottery";
  import {getSigns} from "../../api/write-article/article-right/article-signup";
  import {getTools} from "../../api/write-article/article-right";
  import H5Viewer from "./H5Viewer";
  import VoteCreateDialog from "./Vote/VoteCreateDialog";
  import ArticlePublish from "./ArticlePublish";
  import ArticleTemplate from "./ArticleTemplate";
  import ArticleLottery from "./ArticleLottery";
  import ArticleVote from "./ArticleVote";
  import ArticleSignup from "./ArticleSignup";

  export default {
    name: "ArticleRight",
    components: {
      ArticleSignup,
      ArticleVote,
      ArticleLottery, ArticleTemplate, ArticlePublish, VoteCreateDialog, H5Viewer, DatePicker, Tinymce},
    props: {
      article: Object,
      hide: Boolean,
      voteOptions: Object,
      // 是否开启审核机制，不开启则新增编辑都直接发布到文章列表
      post_check_enable: Number,
    },
    data() {
      return {
        baseUrl: process.env.VUE_APP_ADMINURL || "https://saasapi.shetuan.pro",
        loading: false,
        // 工具栏切换
        tabName: "one",
        toolList: [],
        toolDict: {
          signup: "报名",
          lottery: "抽奖",
          vote: "投票",
          opinion:"信息收集"

        },
        // 统计
        article_content: {
          text_length: 0,
          img_length: 0,
          video_length: 0,
          audio_length: 0
        },
        // 投票工具
        vote: {
          show: false, // 显示新建弹窗
          keyword: "",
          list: [] // 存放搜索结果
        },
        // 抽奖工具
        lottery: {
          page: 1,
          list: [],
          no_more: false,
          no_result: false,
        },
        // 报名工具
        sign: {
          page: 1,
          list: [],
          no_more: false,
          no_result: false
        },
        // iframe预览
        preview: {
          show: false,
          src: "" // 网页链接
        },
      }
    },
    watch: {
      "article.content": {
        handler(val) {
          if (val) this.getArticleContent(val);
        },
      },
      'voteOptions.vote_all': {
        handler(val) {
          this.vote.list = val;
        }
      },
      'vote.keyword'(val, oldVal) {
        if (!val && oldVal) {
        //  清空了关键词，显示所有投票
          this.vote.list = this.voteOptions.vote_all;
        }
      },
      tabName(val) {
        if (val === 'two') {
          if (!this.toolList.length) {
            this.loading = true;
            getTools().then(res => {
              this.toolList = res.data || [];
              this.handleClickTool({label: res.data[0].name})
              this.loading = false;
            }).catch(err => {
              this.loading = false;
            })
          }
        }
      },
    },
    
    created() {
      this.vote.list = this.voteOptions.vote_all;
    },
    methods: {
      updateTop(e) {
        this.article.top_start_time = e[0];
        this.article.top_end_time = e[1];
      },
      // 展开 | 收起
      toggleRight() {
        this.$emit('hide', !this.hide)
      },
      // 统计
      getArticleContent(html) {
        var editor = window.tinymce?.activeEditor;
        /* 网络慢时编辑器未加载出来 */
        if (!editor) return
        if (!editor.plugins.wordcount) return
        this.article_content.text_length = editor.plugins.wordcount.getCount(
          editor
        );
        var imgcount = html.match(/<img\b.*?(?:\>|\/>)/gi);
        this.article_content.img_length = imgcount ? imgcount.length : "0";
        var videocount = html.match(/<video\b.*?(?:\>|\/>)/gi);
        this.article_content.video_length = videocount ? videocount.length : "0";
        var audiocount = html.match(/<audio\b.*?(?:\>|\/>)/gi);
        this.article_content.audio_length = audiocount ? audiocount.length : "0";
      },
      getLotteryList(page) {
        let requestPage = page || this.lottery.page;
        if (this.lottery.no_more && requestPage !== 1) return;
        this.loading = true;
        if (requestPage === 1) {
          this.lottery.list = [];
        }
        this.lottery.no_more = false;
        this.lottery.no_result = false;
        getLotteries(requestPage).then(res => {
          if (requestPage === 1 && !res.data.length) {
            this.lottery.no_result = true;
          }
          if (!res.data || !res.data.length) {
            this.lottery.no_more = true;
          } else {
            this.lottery.list.push(...res.data);
          }
          this.loading = false;
          page ? (this.lottery.page = page + 1) : this.lottery.page++;
        }).catch(err => {
          this.loading = false;
        })
      },
      getSignList(page) {
        let requestPage = page || this.sign.page;
        if (this.sign.no_more && requestPage !== 1) return;
        this.loading = true;
        if (requestPage === 1) {
          this.sign.list = [];
        }
        this.sign.no_more = false;
        this.sign.no_result = false;
        getSigns(requestPage).then(res => {
          if (requestPage === 1 && !res.data.length) {
            this.sign.no_result = true;
          }
          if (!res.data || !res.data.length) {
            this.sign.no_more = true;
          } else {
            this.sign.list.push(...res.data);
          }
          this.loading = false;
          page ? (this.sign.page = page + 1) : this.sign.page++;
        }).catch(err => {
          this.loading = false;
        })
      },
      previewLink(src) {
        this.preview.src = src;
        this.preview.show = true;
      },
      handleClickTool(e) {
        switch(e.label) {
          case "signup":
            this.getSignList(1)
            break;
          case "lottery":
              this.getLotteryList(1);
            break;
          default:
        }
      },
    }
  }
</script>

<style lang="scss">
  .moveR-enter-active,  .moveR-leave-active {
    transition: all 0.3s linear;
    transform: translateX(0);
  }
  .moveR-enter,  .moveR-leave {
    transform: translateX(100%);
  }
  .moveR-leave-to{
    transform: translateX(100%);
  }

  .article-right {
    @include card();
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;

    .right-close {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      left: -16px;
      top: 40vh;
      height: 60px;
      line-height: 60px;
      width: 16px;
      background-color: #e1e1e1;
      color: white;
      padding-left: 2px;
      border-radius: 8px 0 0 8px;

      &:hover {
        opacity: 0.7;
      }
    }

    .tab-content {
      width: 100%;
      overflow-x: hidden;
      flex: 1;
    }

    .right-placeholder {
      width: 420px;
      flex: 1;
    }

    .vote-list {

    }

    .vote-item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }

    .el-tabs--left .el-tabs__header.is-left {
      border-right: 1px solid rgba(241, 241, 241, 1);
      border-bottom: none;
    }

    .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
      border: none;
      height: 50px;

      &.is-active {
        border: none;
      }
    }

    .el-tabs__content {
      padding-top: 10px;
    }

    .el-tab-pane {
      padding-right: $space;
    }

    .el-tabs--left.el-tabs--card .el-tabs__nav {
      border: none;
    }

    .tab-label {
      height: 100%;
    }

    .tool-name {
      /*padding-top: 12px;*/
    }

    .el-tabs__item {
      padding: 0 16px;
    }

    .search-item > span:first-child {
      font-size: 16px;
      font-weight: 400;
      color: rgba(33, 33, 33, 1);
    }

    .search-item > .el-button {
      display: none;
    }

    .search-item:hover > span:first-child + .el-button {
      display: block;
    }

    .vote-container .vote-title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(33, 33, 33, 1);
    }

    .vote-item-container {
      overflow-y: auto;
      /*max-height: 50vh;*/
    }

    .vote-container .progress-bar {
      width: 100%;
      height: 4px;
      background: rgba(241, 241, 241, 1);
      border-radius: 2px;
    }

    .vote-num > span {
      white-space: nowrap;
    }

    .vote-item {
      padding: 10px 0;
      border-bottom: 1px solid rgba(237, 237, 237, 1);
      align-items: flex-start;
    }

    .vote-item:last-child {
      border: none;
    }

    .vote-item > input {
      margin: 0 10px;
    }

    .vote-name {
      font-size: 16px;
      font-weight: 400;
      color: rgba(33, 33, 33, 1);
      margin-bottom: 30px;
    }

    .vote-config {
      font-size: 14px;
      font-weight: 400;
      color: rgba(178, 178, 178, 1);
    }

    .sub-label {
      color: rgba(117, 117, 117, 1);
    }

    #lottery-list, #signup-list {
      .search-item {
        height: 40px;
        padding: 10px 10px 0;
        cursor: pointer;
      }

      .search-item:hover {
        background-color: #F2F8FE;
      }

      .search-item > span:first-child {
        font-size: 16px;
        font-weight: 400;
        color: rgba(33, 33, 33, 1);
      }

      .search-item > span:last-child {
        font-size: 16px;
        font-weight: 400;
        color: rgba(70, 128, 255, 1);
        display: none;
      }

      .search-item > span:last-child:hover {
        opacity: 0.8;
      }

      .search-item:hover > span:first-child + span {
        display: block;
      }
    }

    .my-tabs {
      display: flex;
      justify-content: space-around;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;
      position: relative;

      .my-tab-item {
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        color: #323233;
        line-height: 18px;
        padding: 12px 0 15px;

        &.active {
          font-weight: bold;
        }
      }

      .indicator {
        height: 1px;
        width: calc(100% / 3);
        position: absolute;
        bottom: -1px;
        transition: all .3s;
        left: 0;

        .inner {
          background-color: #303133;
          margin: 0 auto;
          height: 100%;
          width: 37px;
        }
      }
    }

    .my-tabs-content {
      width: 100%;
      flex: 1;
    }

    .search-bar {
      display: flex;

      .search-area {
        width: 204px;
        height: 36px;
        background: #F2F2F2;
        border-radius: 18px;
        display: flex;
        align-items: center;
        padding: 0 15px;

        input {
          border: none;
          background-color: transparent;
          outline: none;
        }
      }

      .search-label-list {
        display: flex;
        align-items: center;
        margin-left: 15px;

        .search-label {
          height: 28px;
          line-height: 28px;
          border-radius: 18px;
          font-size: 14px;
          padding: 0 12px;
          color: #323233;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            opacity: .6;
          }

          &.active {
            background-color: $primary;
            color: #FFFFFF;
            cursor: default;
            opacity: 1;
          }
        }
      }
    }

    .no-template {
      padding-top: 116px;

      .no-template-content {
        font-size: 24px;
        color: #969799;
        line-height: 34px;
        text-align: center;

        img {
          width: 273px;
          margin-bottom: 31px;
        }
      }
    }

    .category-line {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;

      .category-list {
        display: flex;

        .category-item {
          margin-right: 22px;
          line-height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;

          &.active {
            color: $primary;
          }
        }
      }
    }

    .template-list {
      .template-item {
        position: relative;
      }

      .template-item-box {
        padding: 20px 29px;
        border-radius: 2px;
        border: 1px dashed rgba(192, 196, 204, 1);

        &:hover {
          border-color: rgba(53, 118, 255, 1);
        }
      }

      .template-item-content {

      }

      .template-item-like {
        position: absolute;
        bottom: 6px;
        right: 13px;
        width: 28px;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }

    .el-divider--horizontal {
      margin: 30px 0;
      background-color: #F1F1F1;
    }

    .more-btn {
      text-align: center;
    }

    .el-tooltip__popper {
      white-space: nowrap;
    }
  }
</style>
