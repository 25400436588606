<template>
  <el-dialog :visible="value" class="h5-viewer dialog-vertical" close-on-click-modal width="380px" :show-close="false"
    @close="close">
    <Phone :show-nav="false">
      <iframe v-if="src" style="width: 100%;" :src="src"></iframe>
    </Phone>
  </el-dialog>
</template>

<script>
import Phone from "../../../../base/components/Preview/Phone";
export default {
  name: "H5Viewer",
  components: { Phone },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-viewer {
  /* Chrome */
  & ::v-deep .el-dialog {
    background: unset;
    box-shadow: unset;

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }

  /* FireFox */
  .el-dialog {
    background: unset;
    box-shadow: unset;

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
