<template>
  <div class="article-lottery">
    <div class="flex-between">
      <label class="sub-label">选择抽奖项目</label>
    </div>
    <div id="lottery-list" style="max-height: 100%; overflow-y: unset;" @scroll="listenScroll('lottery', $event)">
      <div class="no-template" v-if="noResult">
        <div class="no-template-content">
          <img src="../../assets/images/no-template.png"/>
          <p>暂时没有内容</p>
        </div>
      </div>
      <ul style="list-style: none; padding: 0; " v-if="list.length">
        <li v-for="(li, index) in list" :key="index">
          <div class="search-item flex-col" style="height: auto; ">
            <div style="margin-bottom: 20px;"><p style="color: #333;">{{li.title}}</p></div>
            <div style="margin-bottom: 20px; color: #666;font-size: 12px;">{{li.start_time}} ~ {{li.end_time}}</div>
            <div>
               <el-button type="text" style="margin-right: 10px;" @click="previewLink(li.link)">预览</el-button>
              <el-button type="text" style="margin-right: 10px;" @click="addLottery(li)">选择</el-button>
            </div>
          </div>
          <div class="separate-line"></div>
        </li>
      </ul>
      <el-button v-if="!noMore" type="text" style="width: 100%" @click="$emit('load-more')">点击查看更多</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ArticleLottery",
    props: {
      page: Number,
      list: {
        type: Array,
        default: () => []
      },
      noMore: {
        type: Boolean,
        default: false
      },
      noResult: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {

      }
    },
    methods: {
      /**
       * 添加到文章正文
       * */
      addLottery(obj) {
        let defaultCover = window.serverConfig.VUE_APP_ADMINURL + '/lottery/images/app_lottery_images/cover.png';
        let cover = obj.images && obj.images[0] ? obj.images[0] : defaultCover;
        let titleStyle = 'display: block; font-weight:bold; line-height: 24px; color: #272F3C;';
        let aStyle = 'margin: 0 auto; box-sizing: border-box; display: block; width: 336px; max-width: 100%;border: 1px solid #e7e7e7;padding: 15px 18px 18px;background-color: #F9FAF9;border-radius:4px;';
        /** 需要!important的样式不要加空格，否则!important丢失 */
        let coverStyle = 'width: 100%;height: 122px!important;min-height: 122px; max-width: 300px; object-fit: cover; margin-top: 11px;';
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `
<p>&nbsp;<br/></p>
<div style="">
             <a style="${aStyle}" data-id="${obj.id}" data-type="lottery" href="${obj.link}">
                <span style="${titleStyle}">${obj.title}</span>
                <img style="${coverStyle}" src="${cover}"/>
             </a>
          </div>
          <p>&nbsp;<br/></p>
<p><br/></p>`
        );

        // `
      // <span style="box-sizing: border-box;display:block;margin: 0 auto; max-width: 336px;border: 1px solid #e7e7e7;padding: 15px 18px 18px;background-color: #F9FAF9;border-radius:4px;">
      //     <a data-id="${obj.id}" data-type="lottery" href="${obj.link}" style="${aStyle}">${obj.title}<br/>
      //     <img class="no-preview" src="${cover}" style="${coverStyle}"/>
      //     </a>
      //     </span>&nbsp;
        // `
      },
      previewLink(link) {
        // this.$emit('preview', link)
        window.open(link)
      },
      // 分页加载
      listenScroll(key, e){
        //   var that = this;
        //   let scrollDiv = e.srcElement;
        //   if((scrollDiv.scrollTop + 450) >= scrollDiv.scrollHeight) {
        //     console.log('load more')
        //     if (key === 'signup') {
        //       that.getSignList(that.sign.page)
        //     }
        //     if (key === 'lottery') {
        //       this.getLotteryList(this.page)
        //     }
        //   }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
